import { NumberLike } from "@visx/scale"
import {
  timeDay,
  timeHour,
  timeMinute,
  timeMonth,
  timeSecond,
  timeWeek,
  timeYear,
} from "d3-time"
import { timeFormat } from "d3-time-format"
import { differenceInDays, differenceInHours } from "date-fns"

import { TClockTypeMaybe } from "src/data/user/user"
import { getBrowserClockType } from "src/utils/l10n"

/**
 * Takes an array of data and splits into chunks where each chunk has a dataset
 * where the time of the events happen within the given resolution
 *
 * @param {array} data Data to split into chunks
 * @param {number} resolution Maximum allowed difference in seconds between two datapoints
 * @param {number} slack Allowed number of seconds that the difference is allowed to slack
 */
export function splitToChunks(
  data: { datetime: Date; value: number }[],
  resolution: number = 60,
  slack: number = 0
) {
  const chunks: { datetime: Date; value: number }[][] = []

  let chunkIndex = 0

  data.map((curr, i) => {
    const next = data[i + 1]

    // Make sure chunk array is defined
    if (!chunks[chunkIndex]) {
      chunks[chunkIndex] = []
    }

    chunks[chunkIndex].push(curr)

    if (next && !isValid(curr.datetime, next.datetime, resolution, slack)) {
      chunkIndex++
    }

    return null
  })

  return chunks
}

/**
 * Returns the difference between two dates in seconds. The calculation is not
 * dependant on the order of the dates.
 * @param {date} d1
 * @param {date} d2
 */
function isValid(d1: Date, d2: Date, resolution: number, slack: number) {
  return secondsBetweenDates(d1, d2) <= resolution + slack
}

/**
 * Returns the difference between two dates in seconds. The calculation is not
 * dependant on the order of the dates.
 * @param {date} d1
 * @param {date} d2
 */
function secondsBetweenDates(d1: Date, d2: Date) {
  return Math.abs(d1.getTime() - d2.getTime()) / 1000
}

export function getMotionResolution({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  const minute = 60
  const hour = 3600
  const day = 24 * hour
  const dayDiff = differenceInDays(endDate, startDate)
  const hourDiff = differenceInHours(endDate, startDate)

  if (hourDiff <= 24) {
    return minute * 15
  } else if (dayDiff <= 10) {
    return hour * 2
  } else if (dayDiff <= 28) {
    return hour * 8
  } else if (dayDiff <= 28 * 3) {
    return day
  }
  return day * 3
}

export function getResolution({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) {
  const diff = differenceInDays(endDate, startDate)
  const hours = differenceInHours(endDate, startDate)

  if (hours <= 2) {
    return 60 // 1 minute
  } else if (hours <= 6) {
    return 60 * 2 // 2 minutes
  } else if (hours <= 24) {
    return 60 * 7 // 7 minutes
  } else if (diff <= 5) {
    return 60 * 15 // 15 minutes
  } else if (diff <= 10) {
    return 60 * 30 // 30 minutes
  } else if (diff <= 20) {
    return 60 * 60 * 2 // 30 minutes
  } else if (diff < 80) {
    return 60 * 60 * 2 // 2 hours
  } else if (diff < 500) {
    return 60 * 60 * 24 * 2 // 2 days
  } else {
    return 60 * 60 * 24 * 6 // 6 days
  }
}

export function responsiveGraphYTicks(height: number) {
  if (height <= 200) {
    return 3
  }

  if (height <= 300) {
    return 4
  }

  return 8
}

export function responsiveGraphXTicks(width: number) {
  if (width <= 300) {
    return 2
  }

  if (width <= 400) {
    return 4
  }

  if (width <= 600) {
    return 5
  }

  if (width <= 800) {
    return 6
  }

  if (width <= 1000) {
    return 7
  }

  return 8
}

// Establish the desired formatting options using locale.format():
// https://github.com/d3/d3-time-format/blob/master/README.md#locale_format
const formatMillisecond = timeFormat(".%L")
const formatHour = timeFormat("%H:%M")
const format12Hour = timeFormat("%I:%M %p")
const formatDay = timeFormat("%a %d")
const formatWeek = timeFormat("%b %d")
const formatMonth = timeFormat("%B")
const formatYear = timeFormat("%Y")

export function localizedTimeFormatter(clockType: TClockTypeMaybe) {
  return function (date: Date | (number | NumberLike)) {
    const _clockType = clockType || getBrowserClockType()

    const localizedHourFormatter =
      _clockType === "12" ? format12Hour : formatHour

    let actualDate: Date
    if (isDate(date)) {
      actualDate = date
    } else if (isNumber(date)) {
      actualDate = new Date(date)
    } else {
      actualDate = !date?.valueOf ? (date as Date) : new Date(date?.valueOf())
    }

    let formatter
    if (timeSecond(actualDate) < actualDate) {
      formatter = formatMillisecond
    } else if (timeMinute(actualDate) < actualDate) {
      formatter = localizedHourFormatter
    } else if (timeHour(actualDate) < actualDate) {
      formatter = localizedHourFormatter
    } else if (timeDay(actualDate) < actualDate) {
      formatter = localizedHourFormatter
    } else if (timeMonth(actualDate) < actualDate) {
      if (timeWeek(actualDate) < actualDate) {
        formatter = formatDay
      } else {
        formatter = formatWeek
      }
    } else if (timeYear(actualDate) < actualDate) {
      formatter = formatMonth
    } else {
      formatter = formatYear
    }

    return formatter(actualDate)
  }
}

function isDate(date: Date | (number | NumberLike)): date is Date {
  return date instanceof Date
}

function isNumber(date: Date | (number | NumberLike)): date is number {
  return typeof date === "number"
}
